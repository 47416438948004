<template>
  <div>
    <input type="file" class="d-none" @change="uploadFiles($event.target.files)" accept="*" multiple>
    <b-modal id="uploadprogress" :title="`Uploading ${upload.inProgress.file.name}`" size="lg" centered hide-header-close hide-footer>
      <div class="progress progress-lg"><div role="progressbar" :aria-valuenow="uploadProgressPercent" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-primary progress-bar-striped progress-bar-animated" :style="`width: ${uploadProgressPercent}%`"></div></div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin } from 'bootstrap-vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);

export default {
  props: {
    group: String,
    project: String,
    directory: String,
    type: String,
    el: String,
  },
  data() {
    return {
      upload: {
        lock: false,
        queue: [],
        queueLength: 0,
        progress: 0,
        inProgress: { file: { name: '' } },
      },
    };
  },
  computed: {
    uploadProgressPercent() {
      let pct = (this.upload.queueLength - this.upload.queue.length - 1) / this.upload.queueLength;
      pct += Math.min(this.upload.progress / this.upload.inProgress.file.size / this.upload.queueLength, 1 / this.upload.queueLength);
      return Math.round(pct * 100);
    },
  },
  watch: {
    upload: {
      handler() {
        if (this.upload.inProgress.file.name !== '') {
          this.$bvModal.show('uploadprogress');
        } else {
          this.$bvModal.hide('uploadprogress');
          if (this.upload.queue.length === 0) {
            this.upload.queueLength = 0;
            this.upload.progress = 0;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    uploadFiles(files) {
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        if (f.size > 250 * 1024 * 1024) {
          toast.danger(this, `${f.name} is too large. Maximum file size is 250 MB.`);
        } else {
          // this method is executed by the parent component. find ourselves in it's children.
          let o = this;
          for (let k = 0; k < this.$children.length; k += 1) {
            if (this.$children[k].$options._componentTag === 'upload-modal') { // eslint-disable-line
              o = this.$children[k];
              break;
            }
          }
          // queue file upload
          const fr = new FileReader();
          fr.onload = ((file, t) => (e) => {
            t.upload.queue.push({ file, data: e.target.result });
            t.upload.queueLength += 1; // eslint-disable-line
            t.performUpload();
          })(f, o);
          fr.readAsArrayBuffer(f);
        }
      }
    },
    async performUpload() {
      if (this.upload.lock) {
        return;
      }
      this.upload.lock = true;
      if (!this.upload.queue.length) {
        this.upload.lock = false;
        return;
      }
      this.upload.inProgress = this.upload.queue.shift();

      try {
        const form = new FormData();
        const formData = new Blob([this.upload.inProgress.data], { type: this.upload.inProgress.file.type });
        form.set('file', formData, this.upload.inProgress.file.name);

        let { name } = this.upload.inProgress.file;
        if (this.directory) {
          name = decodeURIComponent(this.directory) + name;
        }

        const res = await this.$api.post(`/projects/${this.group}%2F${this.project}/${this.type}/${encodeURIComponent(name)}`, form, {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (e) => { this.upload.progress = e.loaded; },
        });

        this.$emit('callback', res, this.el);
      } catch (e) {
        toast.danger(this, e.response.data.message);
      }

      // release lock
      this.upload.lock = false;
      this.upload.progress = 0;
      this.upload.inProgress = { file: { name: '' } };
      this.performUpload();
    },
  },
};
</script>
