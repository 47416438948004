<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="issues" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix mb-3">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> New Issue</h5>
            </div>
          </div>

          <div class="widget-list">

            <div class="clearfix">
              <div class="float-left d-none d-md-block">
                <img :src="$auth.profile.avatar" class="rounded-circle avatar-md" alt="">
              </div>
              <div class="float-right issuedetails-comment-body">
                <div class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <div class="form-group">
                        <input type="text" v-model="form.title" class="form-control" placeholder="Title" maxlength="100" ref="title" tabindex="1" required>
                      </div>
                      <div class="form-group rounded comment-box">
                        <b-tabs content-class="p-0 mt-3" no-nav-style no-fade>
                          <template v-slot:tabs-end>
                            <div class="toolbar ml-lg-auto">
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').header()"><i class="fal fa-fw fa-heading" v-b-tooltip.hover title="Add header text"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').bold()"><i class="fal fa-fw fa-bold" v-b-tooltip.hover title="Add bold text"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').italic()"><i class="fal fa-fw fa-italic" v-b-tooltip.hover title="Add italic text"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').quote()"><i class="fal fa-fw fa-quote-right" v-b-tooltip.hover title="Insert a quote"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').code()"><i class="fal fa-fw fa-code" v-b-tooltip.hover title="Insert code"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').link()"><i class="fal fa-fw fa-link" v-b-tooltip.hover title="Add a link"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').ul()"><i class="fal fa-fw fa-list-ul" v-b-tooltip.hover title="Add a bullet list"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').ol()"><i class="fal fa-fw fa-list-ol" v-b-tooltip.hover title="Add a numbered list"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').task()"><i class="fal fa-fw fa-tasks" v-b-tooltip.hover title="Add a task list"></i></button>
                              <button class="btn btn-link" type="button" @click="markdown.insert('form-description').table()"><i class="fal fa-fw fa-table" v-b-tooltip.hover title="Add a table"></i></button>
                            </div>
                          </template>
                          <b-tab title="Write" active>
                            <Textarea ref="form-description" v-model="form.description" class="border-none text-monospace" placeholder="Write a comment" tabindex="2" />
                            <hr class="my-2">
                            <div class="row">
                              <div class="col-sm text-muted small py-1">
                                Styling with Markdown is supported
                              </div>
                              <div class="col-sm text-left text-sm-right">
                                <label class="btn btn-link py-1 px-0 m-0"><i class="fal fa-paperclip text-muted mr-1"></i>Attach a file<upload :group="project.group.path" :project="project.path" type="uploads" @callback="insertLink" /></label>
                              </div>
                            </div>
                          </b-tab>
                          <b-tab title="Preview" class="preview markdown" @click="updatePreview" v-html="preview"></b-tab>
                        </b-tabs>
                      </div>
                      <button @click="postComment()" class="btn btn-primary btn-rounded mt-3" type="button" tabindex="3"><i class="fal fa-check mr-2"></i>Create Issue</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { TabsPlugin, TooltipPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import Textarea from '@/components/Textarea.vue';
import Upload from '@/components/Upload.vue';
import toast from '@/modules/toast';

Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);

export default {
  data() {
    return {
      markdown: undefined,
      preview: '',
      project: {},
      form: {
        title: '',
        description: '',
      },
    };
  },
  methods: {
    updatePreview() {
      this.preview = this.markdown.render(this.form.description);
    },
    postComment() {
      this.form.description = this.form.description.trim();
      this.$api.post(`/projects/${this.project.group.path}%2F${this.project.path}/issues`, this.form)
        .then((res) => {
          this.$router.push(`/${this.project.group.path}/${this.project.path}/issues/${res.data.number}`);
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    insertLink(res) {
      // insert file markdown
      let name = this.markdown.textarea('form-description').selection();
      if (name === '') {
        name = res.data.filename_alt;
      }
      if (res.data.mimetype.startsWith('image/')) {
        this.markdown.insert('form-description').image(name, res.data.url_alt);
      } else {
        this.markdown.insert('form-description').link(name, res.data.url_alt);
      }
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      await import(/* webpackChunkName: "markdown" */ '@/modules/markdown').then(async (md) => {
        this.markdown = md.default;
        this.markdown.config(this, { linkify: true, mentions: true });
      });
      this.$store.commit('ready', true);
      this.$nextTick(() => { this.$refs.title.focus(); });
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    Textarea,
    Upload,
  },
};
</script>
